import React from 'react'
import "../styles/footer.css";

export const Footer = () => {
    return (
        <footer className="footer">
            <p>&copy; Zu ∞ Luz - 2023 - Córdoba, Argentina /
                <a href="https://escanesmarialaura.netlify.app/" target="_blank" rel="noopener noreferrer">
                    <button className='buttonfooter'>Creado por Escanes María Laura</button>
                </a>
            </p>
        </footer>
    )
}


