import React, { useState } from 'react';
import { ModalImagenes } from './ModalImagenes';
import '../../styles/productos.css';

export const Sahumadores = () => {
    const [grupoIndex, setGrupoIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ url: '', name: '' });

    const cambiarGrupo = (index) => {
        setGrupoIndex(index);
    };

    const openModal = (imageUrl, imageName) => {
        setSelectedImage({ url: imageUrl, name: imageName });
        setModalOpen(true);
    };


    const closeModal = () => {
        setSelectedImage({ url: '', name: '' });
        setModalOpen(false);
    };

    const gruposDeImagenes = [
        {
            nombres: [
                "Sahumador Cemento",
                "Sahumador Nativo",
                "Sahumador Acero",
            ],

            imagenes: [
                "/images/Sahumadores/cemento.jpg",
                "/images/Sahumadores/marron.jpg",
                "/images/Sahumadores/acero.jpg",
            ],
        },
        {
            nombres: [
                "Sahumador Om",
                "Sahumador Om pintado",
            ],


            imagenes: [
                "/images/Sahumadores/om-blanco.jpg",
                "/images/Sahumadores/om-pintado.jpg",
            ],

        },

    ];

    const nombresDeGrupos = [
        "Sahumador Sagrada madre",
        "Sahumador Om",

    ];

    return (
        <>
            <div className="pagina">
                <h1 className="tituloProductos">Sahumadores</h1>

                {/* Botones para cambiar los grupos */}
                <div className="botonesCategorias-productos">
                    <div className='botones-productos'>
                        {gruposDeImagenes.map((grupo, index) => (
                            <button
                                key={index}
                                onClick={() => cambiarGrupo(index)}
                                className={index === grupoIndex ? "botonActivo" : ""}
                            >
                                {nombresDeGrupos[index]}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Imágenes del grupo actual */}
                <div className="imagenesGrupo">
                    {gruposDeImagenes[grupoIndex].imagenes.map((imagen, index) => (
                        <div key={index} className="imagen-container" onClick={() => openModal(imagen, gruposDeImagenes[grupoIndex].nombres[index])}>
                            <img src={imagen} className="imghome" alt={`Imagen ${index + 1}`} />
                            {/*<p className="imagen-name">{gruposDeImagenes[grupoIndex].nombres[index]}</p>*/}
                        </div>
                    ))}
                </div>

                {modalOpen && <ModalImagenes imageInfo={selectedImage} onClose={closeModal} />}
            </div>
            <div className='antes-footer'></div>
        </>
    );
};

