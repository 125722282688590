import React from 'react'
import { Principal } from './Principal';
import '../styles/inicio.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BotonCategoria } from './BotonCategoria';

export const Inicio = () => {

    return (
        <>
            <Principal />

            <div className="inicioContenedor">

                <div className='componente'>
                    <div className="categorias">
                        --- CATEGORIAS ---
                    </div>

                    <div className="botones">
                        <BotonCategoria to="/sahumerios" imagenSrc="/images/ImagenesPortada/sahumerios.webp" alt="Icono Sahumerios" texto="SAHUMERIOS" />
                        <BotonCategoria to="/portasahumerios" imagenSrc="/images/ImagenesPortada/portasahumerios.webp" alt="Icono Portasahumerios" texto="PORTASAHUMERIOS" />
                        <BotonCategoria to="/defumacion" imagenSrc="/images/ImagenesPortada/defumacion.webp" alt="Icono Defumacion" texto="DEFUMACION" />
                        <BotonCategoria to="/sahumadores" imagenSrc="/images/ImagenesPortada/sahumadores.webp" alt="Icono Sahumadores" texto="SAHUMADORES" />
                        <BotonCategoria to="/lamparas" imagenSrc="/images/ImagenesPortada/lamparas.webp" alt="Icono Lamparas" texto="LAMPARAS" />
                        <BotonCategoria to="/velas" imagenSrc="/images/ImagenesPortada/velas.webp" alt="Icono Velas" texto="VELAS" />
                        <BotonCategoria to="/cascadahumo" imagenSrc="/images/ImagenesPortada/cascadasHumo.webp" alt="Icono CascadaHumo" texto="CASCADA DE HUMO" />
                        <BotonCategoria to="/conitos" imagenSrc="/images/ImagenesPortada/conitos.webp" alt="Icono Conitos" texto="CONITOS" />
                        <BotonCategoria to="/fuenteagua" imagenSrc="/images/ImagenesPortada/fuentesAgua.webp" alt="Icono FuenteAgua" texto="FUENTES DE AGUA" />
                        <BotonCategoria to="/imagenesyeso" imagenSrc="/images/ImagenesPortada/imagenesYeso.webp" alt="Icono ImagenesYeso" texto="IMAGENES DE YESO" />
                        <BotonCategoria to="/hornitos" imagenSrc="/images/ImagenesPortada/hornitos.webp" alt="Icono Hornitos" texto="HORNITOS" />
                        <BotonCategoria to="/esencias" imagenSrc="/images/ImagenesPortada/esencias.webp" alt="Icono Esencias" texto="ESENCIAS" />
                        <BotonCategoria to="/difusores" imagenSrc="/images/ImagenesPortada/essenze.webp" alt="Icono Difusores" texto="DIFUSORES" />
                        <BotonCategoria to="/varios" imagenSrc="/images/ImagenesPortada/varios.webp" alt="Icono Varios" texto="VARIOS" />
                        <BotonCategoria to="/ofertas" imagenSrc="/images/ImagenesPortada/ofertas.webp" alt="Icono Ofertas" texto="OFERTAS" />
                    </div>

                </div>


               {/* <div className="categorias">
                    --- KITS ---
                </div>

                <div className="slider-container">
                    <ImagenSliderKits />
                </div>

                <div className="categorias">
                    --- COMBOS ---
                </div>

                <div className="slider-container">
                    <ImagenSlider />
                </div>*/}

                <div className="seguime">
                    <a href="https://www.instagram.com/ZULUZ1984/" target="_blank" rel="noopener noreferrer">
                        <div className="seguime-img"></div>
                    </a>
                </div>

                <div className='antes-footer'></div>
            </div>
        </>
    )
}