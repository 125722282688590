import React, { useState } from 'react';
import { ModalImagenes } from './ModalImagenes';
import '../../styles/productos.css';

export const CascadaHumo = () => {
    const [grupoIndex, setGrupoIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ url: '', name: '' });

    const cambiarGrupo = (index) => {
        setGrupoIndex(index);
    };

    const openModal = (imageUrl, imageName) => {
        setSelectedImage({ url: imageUrl, name: imageName });
        setModalOpen(true);
    };


    const closeModal = () => {
        setSelectedImage({ url: '', name: '' });
        setModalOpen(false);
    };

    const gruposDeImagenes = [
        {
            nombres: [
                "Cascada de humo",
                "Cascada de humo",
                "Cascada de humo",
                "Cascada de humo",
                "Cascada de humo",
                "Cascada de humo",
                "Cascada de humo",
                "Cascada de humo",
                "Cascada de humo",
                "Cascada de humo",
                "Cascada de humo",
                "Cascada de humo",
                "Cascada de humo",
                "Cascada de humo",
                "Cascada de humo",
                "Cascada de humo",
                "Cascada de humo",
                "Cascada de humo",
            ],

            imagenes: [
                "/images/CascadaHumo/1.jpg",
                "/images/CascadaHumo/2.jpg",
                "/images/CascadaHumo/3.jpg",
                "/images/CascadaHumo/5.jpg",
                "/images/CascadaHumo/6.jpg",
                "/images/CascadaHumo/7.jpg",
                "/images/CascadaHumo/8.jpg",
                "/images/CascadaHumo/9.jpg",
                "/images/CascadaHumo/10.jpg",
                "/images/CascadaHumo/11.jpg",
                "/images/CascadaHumo/12.jpg",
                "/images/CascadaHumo/13.jpg",
                "/images/CascadaHumo/14.jpg",
                "/images/CascadaHumo/15.jpg",
                "/images/CascadaHumo/16.jpg",
                "/images/CascadaHumo/17.jpg",
                "/images/CascadaHumo/18.jpg",
                "/images/CascadaHumo/19.jpg",
            ],
        }
    ];

    const nombresDeGrupos = [
        "Cascada de humo",
    ];

    return (
        <>
            <div className="pagina">
                <h1 className="tituloProductos">Cascada de humo</h1>

                {/* Botones para cambiar los grupos */}
                <div className="botonesCategorias-productos">
                    <div className='botones-productos'>
                        {gruposDeImagenes.map((grupo, index) => (
                            <button
                                key={index}
                                onClick={() => cambiarGrupo(index)}
                                className={index === grupoIndex ? "botonActivo" : ""}
                            >
                                {nombresDeGrupos[index]}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Imágenes del grupo actual */}
                <div className="imagenesGrupo">
                    {gruposDeImagenes[grupoIndex].imagenes.map((imagen, index) => (
                        <div key={index} className="imagen-container" onClick={() => openModal(imagen, gruposDeImagenes[grupoIndex].nombres[index])}>
                            <img src={imagen} className="imghome" alt={`Imagen ${index + 1}`} />
                            {/*<p className="imagen-name">{gruposDeImagenes[grupoIndex].nombres[index]}</p>*/}
                        </div>
                    ))}
                </div>

                {modalOpen && <ModalImagenes imageInfo={selectedImage} onClose={closeModal} />}
            </div>
            <div className='antes-footer'></div>
        </>
    );
};

