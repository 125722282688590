import React, { useState } from 'react';
import { ModalImagenes } from './ModalImagenes';
import '../../styles/productos.css';

export const Defumacion = () => {
    const [grupoIndex, setGrupoIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ url: '', name: '' });

    const cambiarGrupo = (index) => {
        setGrupoIndex(index);
    };

    const openModal = (imageUrl, imageName) => {
        setSelectedImage({ url: imageUrl, name: imageName });
        setModalOpen(true);
    };


    const closeModal = () => {
        setSelectedImage({ url: '', name: '' });
        setModalOpen(false);
    };

    const gruposDeImagenes = [
        {
            nombres: [
                "Bombita atrae dinero",
                "Bombita abre caminos",
                "Bombita palo santo",
                "Bombita destrabe",
                "Bombita limpieza energética",
                "Bombita defumación completa",
                "Bombita defumación 7 hierbas copal",
                "Bombita 7 poderes",
                "Bombita defumacion 7 chackras",
                "Bomba herbal XL",
            ],

            imagenes: [
                "/images/Defumacion/bombita.jpg",
                "/images/Defumacion/bombita1.jpg",
                "/images/Defumacion/bombita2.jpg",
                "/images/Defumacion/bombita3.jpg",
                "/images/Defumacion/bombita4.jpg",
                "/images/Defumacion/bombita5.jpg",
                "/images/Defumacion/bombita6.jpg",
                "/images/Defumacion/bombita8.jpg",
                "/images/Defumacion/bombita16.jpg",
                "/images/Defumacion/bombaxl.jpg",
            ],
        },
        {
            nombres: [
                "Pastilla 7 días de limpieza",
            ],

            imagenes: [
                "/images/Defumacion/pastillas.jpg",
            ],
        },
        {
            nombres: [
                "Bomba carbón Limpieza energética",
                "Bomba carbón Lavanda",
            ],

            imagenes: [
                "/images/Defumacion/energetica.jpg",
                "/images/Defumacion/lavanda.webp",
            ],
        },
        {
            nombres: [
                "Sahumitos Palo santo",
                "Sahumitos Sándalo",
                "Sahumitos Ruda",
                "Sahumitos de Mirra - Palo santo",
            ],

            imagenes: [
                "/images/Defumacion/sahumito-palosanto.jpg",
                "/images/Defumacion/sahumito-sandalo.jpg",
                "/images/Defumacion/sahumito-ruda.jpg",
                "/images/Defumacion/mirrapalosanto.jpg",
            ],
        },
        {
            nombres: [
                "Resinas Rosa x 25gr",
                "Resinas Mirra Caramelo x 25gr",
                "Resinas Sándalo x 25gr",
                "Resinas Ambar Premium x 25gr",
            ],

            imagenes: [
                "/images/Defumacion/1.jpg",
                "/images/Defumacion/2.jpg",
                "/images/Defumacion/3.jpg",
                "/images/Defumacion/4.jpg",
            ],
        },
        {
            nombres: [
                "Palo santo Peruano x 20gr",
            ],

            imagenes: [
                "/images/Defumacion/palo-santo.jpg",
            ],
        },
    ];

    const nombresDeGrupos = [
        "Bombitas",
        "Pastillas",
        "Bomba Carbón",
        "Sahumitos",
        "Resinas",
        "Palo santo",
    ];

    return (
        <>
            <div className="pagina">
                <h1 className="tituloProductos">Defumación</h1>

                {/* Botones para cambiar los grupos */}
                <div className="botonesCategorias-productos">
                    <div className='botones-productos'>
                        {gruposDeImagenes.map((grupo, index) => (
                            <button
                                key={index}
                                onClick={() => cambiarGrupo(index)}
                                className={index === grupoIndex ? "botonActivo" : ""}
                            >
                                {nombresDeGrupos[index]}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Imágenes del grupo actual */}
                <div className="imagenesGrupo">
                    {gruposDeImagenes[grupoIndex].imagenes.map((imagen, index) => (
                        <div key={index} className="imagen-container" onClick={() => openModal(imagen, gruposDeImagenes[grupoIndex].nombres[index])}>
                            <img src={imagen} className="imghome" alt={`Imagen ${index + 1}`} />
                            {/*<p className="imagen-name">{gruposDeImagenes[grupoIndex].nombres[index]}</p>*/}
                        </div>
                    ))}
                </div>

                {modalOpen && <ModalImagenes imageInfo={selectedImage} onClose={closeModal} />}
            </div>
            <div className='antes-footer'></div>
        </>
    );
};



