import React, { useState } from 'react';
import { ModalImagenes } from './ModalImagenes';
import '../../styles/productos.css';

export const Difusores = () => {
    const [grupoIndex, setGrupoIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ url: '', name: '' });

    const cambiarGrupo = (index) => {
        setGrupoIndex(index);
    };

    const openModal = (imageUrl, imageName) => {
        setSelectedImage({ url: imageUrl, name: imageName });
        setModalOpen(true);
    };


    const closeModal = () => {
        setSelectedImage({ url: '', name: '' });
        setModalOpen(false);
    };

    const gruposDeImagenes = [
        {
            nombres: [
                "Difusores 130ml",
                "Aromas: Difusores 130ml",
            ],

            imagenes: [
                "/images/Difusores/coco-lima.webp",
                "/images/Difusores/difusor.webp",
            ],
        },
        {
            nombres: [
                "Difusores de auto 6ml",
                "Aromas: Difusores de auto 6ml",
            ],

            imagenes: [
                "/images/Difusores/1.jpg",
                "/images/Difusores/aromas.png",
            ],
        },
        {
            nombres: [
                "Frutilla",
                "Jazmin",
                "Lavanda",
                "Palo santo",
                "Sandalo",
                "Rosa",
                "Vainilla",
            ],


            imagenes: [
                "/images/Difusores/frutillaspray.jpg",
                "/images/Difusores/jazminspray.jpg",
                "/images/Difusores/lavandaspray.jpg",
                "/images/Difusores/palosantospray.jpg",
                "/images/Difusores/sandalospray.jpg",
                "/images/Difusores/rosaspray.jpg",
                "/images/Difusores/vainillaspray.jpg",
            ],
        },

    ];

    const nombresDeGrupos = [
        "Difusor de aromas 130ml",
        "Difusor de auto 6ml",
        "Aromatizante de ambiente y telas 110ml",
    ];

    return (
        <>
            <div className="pagina">
                <h1 className="tituloProductos">Difusores</h1>

                {/* Botones para cambiar los grupos */}
                <div className="botonesCategorias-productos">
                    <div className='botones-productos'>
                        {gruposDeImagenes.map((grupo, index) => (
                            <button
                                key={index}
                                onClick={() => cambiarGrupo(index)}
                                className={index === grupoIndex ? "botonActivo" : ""}
                            >
                                {nombresDeGrupos[index]}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Imágenes del grupo actual */}
                <div className="imagenesGrupo">
                    {gruposDeImagenes[grupoIndex].imagenes.map((imagen, index) => (
                        <div key={index} className="imagen-container" onClick={() => openModal(imagen, gruposDeImagenes[grupoIndex].nombres[index])}>
                            <img src={imagen} className="imghome" alt={`Imagen ${index + 1}`} />
                            {/*<p className="imagen-name">{gruposDeImagenes[grupoIndex].nombres[index]}</p>*/}
                        </div>
                    ))}
                </div>

                {modalOpen && <ModalImagenes imageInfo={selectedImage} onClose={closeModal} />}
            </div>
            <div className='antes-footer'></div>
        </>
    );
};

