import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../styles/navBar.css";

export const NavBar = () => {
    const [showCategories, setShowCategories] = useState(false);

    const toggleCategories = () => {
        setShowCategories(!showCategories);
    };

    const closeCategories = () => {
        setShowCategories(false);
    };

    const handleCategoryClick = () => {
        closeCategories();
    };

    return (
        <div className="enlaces">
            <Link to="/inicio">Inicio</Link>

            <div className="categoria-btn" onMouseEnter={toggleCategories} onMouseLeave={closeCategories}>
                <span>Categorías</span>
                {showCategories && (
                    <div className="categorias-menu">
                        <Link to="/sahumerios" onClick={handleCategoryClick}>Sahumerios</Link>
                        <Link to="/portasahumerios" onClick={handleCategoryClick}>Portasahumerios</Link>
                        <Link to="/defumacion" onClick={handleCategoryClick}>Defumacion</Link>
                        <Link to="/sahumadores" onClick={handleCategoryClick}>Sahumadores</Link>
                        <Link to="/lamparas" onClick={handleCategoryClick}>Lamparas</Link>
                        <Link to="/velas" onClick={handleCategoryClick}>Velas</Link>
                        <Link to="/cascadahumo" onClick={handleCategoryClick}>Cascadas de humo</Link>
                        <Link to="/conitos" onClick={handleCategoryClick}>Conitos</Link>
                        <Link to="/fuenteagua" onClick={handleCategoryClick}>Fuentes de agua</Link>
                        <Link to="/imagenesyeso" onClick={handleCategoryClick}>Imagenes de yeso</Link>
                        <Link to="/hornitos" onClick={handleCategoryClick}>Hornitos </Link>
                        <Link to="/esencias" onClick={handleCategoryClick}>Esencias </Link>
                        <Link to="/difusores" onClick={handleCategoryClick}>Difusores</Link>
                        <Link to="/varios" onClick={handleCategoryClick}>Varios</Link>
                        <Link to="/ofertas" onClick={handleCategoryClick}>Ofertas</Link>
                      {/*  <Link to="/kits" onClick={handleCategoryClick}>Kits</Link>
                        <Link to="/combos" onClick={handleCategoryClick}>Combos</Link> */}
                    </div>
                )}
            </div>

           {/*   <Link to="/kits">Kits</Link>
            <Link to="/combos">Combos</Link>*/}
        </div>
    );
};
