import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { Inicio } from '../components/Inicio';
import { Footer } from '../components/Footer';
import { Sahumerios } from '../components/productos/Sahumerios';
import { Portasahumerios } from '../components/productos/Portasahumerios';
import { Defumacion } from '../components/productos/Defumacion';
import { Sahumadores } from '../components/productos/Sahumadores';
import { Lamparas } from '../components/productos/Lamparas';
import { Velas } from '../components/productos/Velas';
import { CascadaHumo } from '../components/productos/CascadaHumo';
import { Conitos } from '../components/productos/Conitos';
import { FuenteAgua } from '../components/productos/FuenteAgua';
import { ImagenesYeso } from '../components/productos/ImagenesYeso';
import { Hornitos } from '../components/productos/Hornitos';
import { Esencias } from '../components/productos/Esencias';
import { Difusores } from '../components/productos/Difusores';
import { Varios } from '../components/productos/Varios';
import { Combos } from '../components/productos/Combos';
import { Ofertas} from '../components/productos/Ofertas';
import { NavBar } from '../components/NavBar';
import { Kits } from '../components/productos/Kits';

export const MisRutas = () => {
    return (
        <BrowserRouter>
        
            <NavBar />
            <div className='despues-navbar'></div>
           
            <section className="content">
                <Routes>
                    <Route path="/" element={<Navigate to="/inicio" />} />
                    <Route path="/inicio" element={<Inicio />} />
                    <Route path="/sahumerios" element={<Sahumerios />} />
                    <Route path="/portasahumerios" element={<Portasahumerios />} />
                    <Route path="/defumacion" element={<Defumacion />} />
                    <Route path="/sahumadores" element={<Sahumadores />} />
                    <Route path="/lamparas" element={<Lamparas />} />
                    <Route path="/velas" element={<Velas />} />
                    <Route path="/cascadahumo" element={<CascadaHumo />} />
                    <Route path="/conitos" element={<Conitos />} />
                    <Route path="/fuenteagua" element={<FuenteAgua />} />
                    <Route path="/imagenesyeso" element={<ImagenesYeso />} />
                    <Route path="/hornitos" element={<Hornitos />} />
                    <Route path="/esencias" element={<Esencias />} />
                    <Route path="/difusores" element={<Difusores />} />
                    <Route path="/varios" element={<Varios />} />
                    <Route path="/combos" element={<Combos />} />
                    <Route path="/ofertas" element={<Ofertas />} />
                    <Route path="/kits" element={<Kits />} />
                    <Route path="*" element={<Navigate to="/inicio" />} />
                </Routes>
            </section>

            <Footer />

        </BrowserRouter>
    )
}
