import React from 'react';
import { BotonWhatsApp } from './components/BotonWhatsApp';
import { MisRutas } from "./router/MisRutas";
import { BotonInstagram } from './components/BotonInstagram';
import Scroll from './components/Scroll';

function App() {
    return (
        <div className="layout">
            <MisRutas />

            <BotonWhatsApp iconColor="green" iconSize="3em" />
            <BotonInstagram iconColor="D52577" iconSize="3em" />

            <Scroll />
        </div>
    );
}

export default App;
