import React, { useState } from 'react';
import { ModalImagenes } from './ModalImagenes';
import '../../styles/productos.css';

export const Lamparas = () => {
    const [grupoIndex, setGrupoIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ url: '', name: '' });

    const cambiarGrupo = (index) => {
        setGrupoIndex(index);
    };

    const openModal = (imageUrl, imageName) => {
        setSelectedImage({ url: imageUrl, name: imageName });
        setModalOpen(true);
    };


    const closeModal = () => {
        setSelectedImage({ url: '', name: '' });
        setModalOpen(false);
    };

    const gruposDeImagenes = [
        {
            nombres: [
                "Lamparas de sal mediana",
                "Lamparas de sal mediana",
                "Lamparas de sal mediana",
                "Lamparas de sal grande",
            ],

            imagenes: [
                "/images/Lamparas/9.jpg",
                "/images/Lamparas/10.jpg",
                "/images/Lamparas/11.jpg",
                "/images/Lamparas/12.jpg",
            ],
        },
        {
            nombres: [
                "Lamparas con sal",
                "Lamparas con sal",
                "Lamparas con sal",
                "Lamparas con sal",
                "Lamparas con sal",
                "Lamparas con sal",
                "Lamparas con sal",
                "Lamparas con sal",
            ],

            imagenes: [
                "/images/Lamparas/5.jpg",
                "/images/Lamparas/8.jpg",
                "/images/Lamparas/1.jpg",
                "/images/Lamparas/3.jpg",
                "/images/Lamparas/2.jpg",
                "/images/Lamparas/4.jpg",
                "/images/Lamparas/6.jpg",
                "/images/Lamparas/7.jpg",
            ],
        },
        {
            nombres: [
                "Velador amarillo - flores",
                "Velador violeta",
                "Velador amarillo",

            ],

            imagenes: [
                "/images/Lamparas/velador-amarillo.jpg",
                "/images/Lamparas/velador-violeta.jpg",
                "/images/Lamparas/velador-amarillo1.jpg",
            ],
        },
    ];

    const nombresDeGrupos = [
        "Lamparas de sal",
        "Lamparas con sal",
        "Veladores",
    ];

    return (
        <>
            <div className="pagina">
                <h1 className="tituloProductos">Lamparas</h1>

                {/* Botones para cambiar los grupos */}
                <div className="botonesCategorias-productos">
                    <div className='botones-productos'>
                        {gruposDeImagenes.map((grupo, index) => (
                            <button
                                key={index}
                                onClick={() => cambiarGrupo(index)}
                                className={index === grupoIndex ? "botonActivo" : ""}
                            >
                                {nombresDeGrupos[index]}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Imágenes del grupo actual */}
                <div className="imagenesGrupo">
                    {gruposDeImagenes[grupoIndex].imagenes.map((imagen, index) => (
                        <div key={index} className="imagen-container" onClick={() => openModal(imagen, gruposDeImagenes[grupoIndex].nombres[index])}>
                            <img src={imagen} className="imghome" alt={`Imagen ${index + 1}`} />
                            {/*<p className="imagen-name">{gruposDeImagenes[grupoIndex].nombres[index]}</p>*/}
                        </div>
                    ))}
                </div>

                {modalOpen && <ModalImagenes imageInfo={selectedImage} onClose={closeModal} />}
            </div>
            <div className='antes-footer'></div>
        </>
    );
};

