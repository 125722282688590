import React, { useState, useEffect } from 'react';
import { IoArrowUpCircleOutline } from "react-icons/io5";
import '../styles/scroll.css';

const Scroll = () => {
    const [showScroll, setShowScroll] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => {
            window.removeEventListener('scroll', checkScrollTop);
        };
    }, []);

    const checkScrollTop = () => {
        const scrollThreshold = 100;
        setShowScroll(window.pageYOffset > scrollThreshold);
    };

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className={showScroll ? 'scroll-to-top show' : 'scroll-to-top'} onClick={scrollTop}>
            <IoArrowUpCircleOutline size="2.5em" />
        </div>
    );
};

export default Scroll;

