import React from 'react';
import { FaWhatsappSquare } from "react-icons/fa";

export const BotonWhatsApp = ({ iconSize, iconColor }) => {

    const iconContainerStyle = {
        position: 'fixed',
        bottom: '70px',
        right: '20px',
        zIndex: '1000',
    };

    const iconStyle = {
        fontSize: iconSize,
        color: iconColor || 'green',
    };

    const tooltipStyle = {
        position: 'absolute',
        left: '-186px',
        top: '10px',
        backgroundColor: '#fff',
        padding: '5px',
        borderRadius: '5px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        display: 'none',
        border: '1px solid #000',
    };

    const handleHover = () => {
        document.getElementById('whatsapp-tooltip').style.display = 'block';
    };

    const handleLeave = () => {
        document.getElementById('whatsapp-tooltip').style.display = 'none';
    };

    return (
        <div
            style={iconContainerStyle}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
        >
            <a className='botonIncio' href="https://wa.me/543513593611" target="_blank" rel="noopener noreferrer">
                <FaWhatsappSquare style={iconStyle} />
            </a>

            <div id="whatsapp-tooltip" style={tooltipStyle}>
                Contactame por WhatsApp
            </div>
        </div>
    );
};

