import React, { useState } from 'react';
import { ModalImagenes } from './ModalImagenes';
import '../../styles/productos.css';

export const ImagenesYeso = () => {
    const [grupoIndex, setGrupoIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ url: '', name: '' });

    const cambiarGrupo = (index) => {
        setGrupoIndex(index);
    };

    const openModal = (imageUrl, imageName) => {
        setSelectedImage({ url: imageUrl, name: imageName });
        setModalOpen(true);
    };


    const closeModal = () => {
        setSelectedImage({ url: '', name: '' });
        setModalOpen(false);
    };

    const gruposDeImagenes = [
        {
            nombres: [
                "Buda amarillo",
                "Buda blanco",
                "Buda fucsia",
                "Buda rojo",
                "Buda verde",
                "Buda rosa",
                "Buda violeta",
            ],

            imagenes: [
                "/images/ImagenesYeso/buda-amarillo.jpg",
                "/images/ImagenesYeso/buda-blanco.jpg",
                "/images/ImagenesYeso/buda-fucsia.jpg",
                "/images/ImagenesYeso/buda-rojo.jpg",
                "/images/ImagenesYeso/buda-verde.jpg",
                "/images/ImagenesYeso/buda-rosa.jpg",
                "/images/ImagenesYeso/buda-violeta.jpg",
            ],
        },
        {
            nombres: [
                "Buda grande amarillo",
                "Buda grande blanco",
                "Buda grande fucsia",
                "Buda grande rojo",
                "Buda grande verde",
                "Buda grande rosa",
            ],

            imagenes: [
                "/images/ImagenesYeso/budaG-amarillo.jpg",
                "/images/ImagenesYeso/budaG-blanco.jpg",
                "/images/ImagenesYeso/budaG-fucsia.jpg",
                "/images/ImagenesYeso/budaG-rojo.jpg",
                "/images/ImagenesYeso/budaG-verde.jpg",
                "/images/ImagenesYeso/budaG-rosa.jpg",
            ],
        },
        {
            nombres: [
                "Buda meditación violeta",
                "Buda meditación amarillo",
                "Buda meditación azul",
            ],

            imagenes: [
                "/images/ImagenesYeso/meditacion-violeta.jpg",
                "/images/ImagenesYeso/meditacion-amarillo.jpg",
                "/images/ImagenesYeso/meditacion-azul.jpg",
            ],
        },
        {
            nombres: [
                "Buda sabio - No escucho",
                "Buda sabio - No hablo",
                "Buda sabio - No veo",
            ],

            imagenes: [
                "/images/ImagenesYeso/Buda-orejas.jpg",
                "/images/ImagenesYeso/buda-boca.jpg",
                "/images/ImagenesYeso/buda-ojos.jpg",
            ],
        },
        {
            nombres: [
                "Gato negro",
            ],

            imagenes: [
                "/images/ImagenesYeso/gato-negro-chico.jpg",
            ],
        },
        {
            nombres: [
                "Gato blanco",
                "Gato negro",
            ],

            imagenes: [
                "/images/ImagenesYeso/gato-blanco.jpg",
                "/images/ImagenesYeso/gato-negro.jpg",
            ],
        },
    ];

    const nombresDeGrupos = [
        "Buda de colores 13cm",
        "Buda de colores 20cm",
        "Buda meditación 27cm",
        "Budas sabios 22cm",
        "Gatos 30cm",
        "Gatos 36cm",
    ];

    return (
        <>
            <div className="pagina">
                <h1 className="tituloProductos">Imagenes de yeso</h1>

                {/* Botones para cambiar los grupos */}
                <div className="botonesCategorias-productos">
                    <div className='botones-productos'>
                        {gruposDeImagenes.map((grupo, index) => (
                            <button
                                key={index}
                                onClick={() => cambiarGrupo(index)}
                                className={index === grupoIndex ? "botonActivo" : ""}
                            >
                                {nombresDeGrupos[index]}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Imágenes del grupo actual */}
                <div className="imagenesGrupo">
                    {gruposDeImagenes[grupoIndex].imagenes.map((imagen, index) => (
                        <div key={index} className="imagen-container" onClick={() => openModal(imagen, gruposDeImagenes[grupoIndex].nombres[index])}>
                            <img src={imagen} className="imghome" alt={`Imagen ${index + 1}`} />
                            {/*<p className="imagen-name">{gruposDeImagenes[grupoIndex].nombres[index]}</p>*/}
                        </div>
                    ))}
                </div>

                {modalOpen && <ModalImagenes imageInfo={selectedImage} onClose={closeModal} />}
            </div>
            <div className='antes-footer'></div>
        </>
    );
};

