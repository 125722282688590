import React, { useState } from 'react';
import { ModalImagenes } from './ModalImagenes';
import '../../styles/productos.css';

export const Velas = () => {
    const [grupoIndex, setGrupoIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ url: '', name: '' });

    const cambiarGrupo = (index) => {
        setGrupoIndex(index);
    };

    const openModal = (imageUrl, imageName) => {
        setSelectedImage({ url: imageUrl, name: imageName });
        setModalOpen(true);
    };


    const closeModal = () => {
        setSelectedImage({ url: '', name: '' });
        setModalOpen(false);
    };

    const gruposDeImagenes = [
        {
            nombres: [
                "Veloncitos neutros rojo",
                "Veloncitos neutros azul",
                "Veloncitos neutros verde",
            ],


            imagenes: [
                "/images/Velas/neutro.jpg", 
                "/images/Velas/neutro1.jpg", 
                "/images/Velas/neutro2.jpg", 
            ],
        },
        {
            nombres: [
                "Frutilla",
                "Limón",
                "Uva dulce",
                "Vainilla",
                "jazmín",
                "Lavanda",
                "Mango-Maracuyá",
            ],


            imagenes: [
                "/images/Velas/frutilla.jpg", 
                "/images/Velas/limon.jpg", 
                "/images/Velas/uva.jpg", 
                "/images/Velas/vainilla.jpg", 
                "/images/Velas/jazmin.jpg", 
                "/images/Velas/lavanda.jpg", 
                "/images/Velas/mango-maracuya.jpg", 
            ],
        },
        {
            nombres: [
                "Velas de noche",
                "Velas de noche",
            ],


            imagenes: [
                "/images/Velas/noche.jpg", 
                "/images/Velas/noche1.jpg",
            ],
        },

    ];

    const nombresDeGrupos = [
        "Veloncitos neutros",
        "Veloncitos aromaticos",
        "Velas de noche",
      
    ];

    return (
        <>
            <div className="pagina">
                <h1 className="tituloProductos">Velas</h1>

                {/* Botones para cambiar los grupos */}
                <div className="botonesCategorias-productos">
                    <div className='botones-productos'>
                        {gruposDeImagenes.map((grupo, index) => (
                            <button
                                key={index}
                                onClick={() => cambiarGrupo(index)}
                                className={index === grupoIndex ? "botonActivo" : ""}
                            >
                                {nombresDeGrupos[index]}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Imágenes del grupo actual */}
                <div className="imagenesGrupo">
                    {gruposDeImagenes[grupoIndex].imagenes.map((imagen, index) => (
                        <div key={index} className="imagen-container" onClick={() => openModal(imagen, gruposDeImagenes[grupoIndex].nombres[index])}>
                            <img src={imagen} className="imghome" alt={`Imagen ${index + 1}`} />
                            {/*<p className="imagen-name">{gruposDeImagenes[grupoIndex].nombres[index]}</p>*/}
                        </div>
                    ))}
                </div>

                {modalOpen && <ModalImagenes imageInfo={selectedImage} onClose={closeModal} />}
            </div>
            <div className='antes-footer'></div>
        </>
    );
};

