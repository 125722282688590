import React from 'react'
import '../styles/principal.css';

export const Principal = () => {
    return (
        <div className="contenido">

            <div className='contenido1'>
                <img src='/images/ImagenesPortada/imglogo.webp' alt="Logo" className='imgLogo' />
            </div>

            <h1 className='tituloInicio'>
                Venta de sahumerios, portasahumerios, sahumitos, sahumadores, cascadas de humo, conitos, fuentes de agua, lamparas de sal, velas, etc..
            </h1>
        </div>
    )
}