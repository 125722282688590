import React, { useState } from 'react';
import { ModalImagenes } from './ModalImagenes';
import '../../styles/productos.css';

export const Sahumerios = () => {
    const [grupoIndex, setGrupoIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ url: '', name: '' });

    const cambiarGrupo = (index) => {
        setGrupoIndex(index);
    };

    const openModal = (imageUrl, imageName) => {
        setSelectedImage({ url: imageUrl, name: imageName });
        setModalOpen(true);
    };


    const closeModal = () => {
        setSelectedImage({ url: '', name: '' });
        setModalOpen(false);
    };

    const gruposDeImagenes = [
        {
            nombres: [
                "Sahumerio palo santo con violetas",
                "Sahumerio palo santo con fresias",
                "Sahumerio palo santo con ruda",
                "Sahumerio palo santo con pachuli",
                "Sahumerio palo santo con vainilla",
                "Sahumerio palo santo con rosa",
                "Sahumerio palo santo con Lavanda",
                "Sahumerio palo santo con yagra",
                "Sahumerio palo santo",
                "Sahumerio palo santo con incienso",
                "Sahumerio palo santo con copal",
                "Sahumerio palo santo con mirra",
                "Sahumerio palo santo con sándalo",
                "Sahumerio palo santo con champa",
                "Sahumerio palo santo con romero",
                "Sahumerio palo santo con jazmín",
            ],

            imagenes: [
                "/images/Sahumerios/palosanto.jpg", //palo santo con violetas
                "/images/Sahumerios/palosanto1.jpg", //palo santo con fresias
                "/images/Sahumerios/palosanto2.jpg", //palo santo con ruda
                "/images/Sahumerios/palosanto3.jpg", //palo santo con pachuli
                "/images/Sahumerios/palosanto4.jpg", //palo santo con vainilla
                "/images/Sahumerios/palosanto5.jpg", //palo santo con rosa
                "/images/Sahumerios/palosanto6.jpg", //palo santo con lavanda
                "/images/Sahumerios/palosanto7.jpg", //palo santo con yagra
                "/images/Sahumerios/palosanto8.jpg", //palo santo
                "/images/Sahumerios/palosanto9.jpg", //palo santo con incienso
                "/images/Sahumerios/palosanto10.jpg", //palo santo con copal
                "/images/Sahumerios/palosanto11.jpg", //palo santo con mirra
                "/images/Sahumerios/palosanto12.jpg", //palo santo con sandalo
                "/images/Sahumerios/palosanto13.jpg", //palo santo con champa
                "/images/Sahumerios/palosanto14.jpg", //palo santo con romero
                "/images/Sahumerios/palosanto15.jpg", //palo santo con jazmin

            ],
        },
        {
            nombres: [
                "Té verde y Champa",
                "Fresias",
                "Clavo de Olor y Rosas",
            ],

            imagenes: [
                "/images/Sahumerios/botanica3.jpg", //te verde y champa
                "/images/Sahumerios/botanica5.jpg", //fresias
                "/images/Sahumerios/botanica7.jpg", //clavo de olor y rosas
            ],
        },
        {
            nombres: [
                "Sahumerio Incienso natural",
                "Sahumerio Mirra natural",
                "Sahumerio Copal natural",
                "Sahumerio Ruda romero incienso natural",
                "Sahumerio Citronela naranja palo santo natural",
                "Sahumerio Sándalo y Olíbano natural",
                "Sahumerio Pachuli natural",
                "Sahumerio Ambar natural",
                "Sahumerio Benjui natural",
            ],

            imagenes: [
                "/images/Sahumerios/natural.jpg", //natural
                "/images/Sahumerios/natural1.jpg", //mirra natural
                "/images/Sahumerios/natural2.jpg", //copal natural
                "/images/Sahumerios/natural4.jpg", //ruda, romero y incienso natural
                "/images/Sahumerios/natural5.jpg", //citronela, naranja y palo santo natural
                "/images/Sahumerios/natural6.jpg", //sandalo y olibano natural
                "/images/Sahumerios/pachuli-natural.jpg",
                "/images/Sahumerios/ambar_natural.jpg", 
                "/images/Sahumerios/benjui-nauraL.jpg", 
            ],
        },
        {
            nombres: [
                "Jazmín",
                "Naranja Vainilla",
                "Rosa Mosqueta",
                "Yerba Mate y Menta",
                "Lavanda Silvestre",
                "Limón",
            ],

            imagenes: [
                "/images/Sahumerios/patagonia.jpg", //jazmin
                "/images/Sahumerios/patagonia1.jpg", //naranja y vainilla
                "/images/Sahumerios/patagonia7.jpg", //rosa mosqueta
                "/images/Sahumerios/patagonia8.jpg", //yerba mate y menta
                "/images/Sahumerios/patagonia10.jpg", //lavanda silvestre
                "/images/Sahumerios/patagonia11.jpg", //limon
            ],
        },
        {
            nombres: [
                "Sahumerio lavanda olíbano hierbas",
                "Sahumerio incienso blanco puro",
                "Sahumerio salvia blanca",
                "Sahumerio rosas y olíbano",
                "Sahumerio caléndula manzanilla incienso",
                "Sahumerio anís, canela y olíbano",
                "Sahumerio eucalipto, laurel y cedro",
            ],

            imagenes: [
                "/images/Sahumerios/hierbas.jpg", //lavanda, olibano y hierbas
                "/images/Sahumerios/hierbas1.jpg", //incienso blanco puro
                "/images/Sahumerios/hierbas2.jpg", //salvia blanca
                "/images/Sahumerios/hierbas3.jpg", //rosas y olibano
                "/images/Sahumerios/hierbas4.jpg", //calendula, manzanilla y incienso
                "/images/Sahumerios/hierbas5.jpg", //anis, banela y olibano
                "/images/Sahumerios/hierbas6.jpg", //eucalipto, laurel y cedropapa
            ],
        },
        {
            nombres: [
                "Yagra- Vainilla- Rosa",
                "Yagra- Violetas - Lavanda",
            ],

            imagenes: [
                "/images/Sahumerios/yagra.jpg", //yagra, rosa y vainilla
                "/images/Sahumerios/yagra1.jpg", //yagra, violetas y lavanda                       
            ],
        },
        {
            nombres: [
                "Sahumerio 5 Elementos Fuego - Fuerza",
                "Sahumerio 5 Elementos Tierra - Armonía",
                "Sahumerio 5 Elementos Agua - Renacer",
                "Sahumerio 5 Elementos Aire - Alegría",
                "Sahumerio 5 Elementos Éter - Claridad",
            ],

            imagenes: [
                "/images/Sahumerios/5fuego.webp",
                "/images/Sahumerios/5tierra.webp",
                "/images/Sahumerios/5agua.jpg",
                "/images/Sahumerios/5aire.jpg",
                "/images/Sahumerios/5eter.jpg",
            ],
        },
        {
            nombres: [
                "Revitalizar",
                "Armonizar",
                "Renacer",
                "Sanar",
            ],

            imagenes: [
                "/images/Sahumerios/revitalizar.webp",
                "/images/Sahumerios/armonizar.jpg",
                "/images/Sahumerios/renacer.webp",
                "/images/Sahumerios/sanar.webp",
            ],
        },
        {
            nombres: [
                "Sahumerio en Polvo Limpieza energética",
                "Sahumerio en Polvo Palo Santo con Madera de Oriente",
                "Sahumerio en Polvo Citronella con Naranja",
                "Sahumerio en Polvo Sándalo de la India",
                "Sahumerio en Polvo Lavanda con flores de Lavanda",
                "Sahumerio en Polvo Benjuí con Estoraque",
            ],

            imagenes: [
                "/images/Sahumerios/plimpiezaenergetica.webp",
                "/images/Sahumerios/ppaloSanto_madera_oriente.webp",
                "/images/Sahumerios/pcitronella_con_naranja.jpg",
                "/images/Sahumerios/psandalodelaindia.webp",
                "/images/Sahumerios/plavandafloresdelavanda.webp",
                "/images/Sahumerios/pbenjuiyestoranque.jpg",
            ],
        },
        {
            nombres: [
                "Sahumerios 7 Chakras",
                "Sahumerios 7 Chakras",
            ],


            imagenes: [
                "/images/Sahumerios/7chakras2.jpg",
                "/images/Sahumerios/7chakras1.png",
            ],
        },
        {
            nombres: [
                "Copal - Rromero",
                "Yagra - Palo Santo",
                "Incienso - 7 Hierbas",
                "Palo Santo - Incienso",
            ],


            imagenes: [
                "/images/Sahumerios/sahumeriosDuo.jpg", //limpieza y armonia
                "/images/Sahumerios/sahumeriosDuo1.jpg", //fortuna Y purificar
                "/images/Sahumerios/sahumeriosDuo2.jpg", //7 poderes y renacer
                "/images/Sahumerios/sahumeriosDuo3.jpg", //sanacion y apertura
            ],
        },
        {
            nombres: [
                "Pachuli - Palo Santo",
            ],


            imagenes: [
                "/images/Sahumerios/lineaconos.jpg", //pachuli y palo santo
            ],
        },
        {
            nombres: [
                "Sahumerio triangular incienso - ámbar",
                "Sahumerio triangular Benjuí - Yagra",
            ],


            imagenes: [
                "/images/Sahumerios/triangulares.jpg", //incienso y ambar
                "/images/Sahumerios/triangulares1.jpg", //benjui y yagra
            ],
        },
        {
            nombres: [
                "Sahumerio Tallados 60cm Citronella",
                "Sahumerio Tallados 60cm Floral",
                "Sahumerio Tallados 60cm",
                "Sahumerio Tibetano 66cm",
            ],


            imagenes: [
                "/images/Sahumerios/tallado-citronella.jpg",
                "/images/Sahumerios/tallado-frutal.jpg",
                "/images/Sahumerios/tallado-rio.jpg",
                "/images/Sahumerios/tibetano.jpg",
            ],
        },
    ];

    const nombresDeGrupos = [
        "Línea sahumerios Palo Santo",
        "Línea sahumerios Botánico",
        "Línea sahumerios Natural",
        "Línea Patagonia",
        "Línea sahumerios de Hierbas, Flores y Resinas",
        "Línea Yagra",
        "Línea sahumerios 5 Elementos",
        "Sahumerios Energia Limpia",
        "Sahumerios en Polvo",
        "Sahumerios 7 Chakras",
        "Sahumerios DUO",
        "Línea Conos",
        "Línea de sahumerios Triangulares",
        "Línea para el exterior - Iluminarte",
    ];

    return (
        <>
            <div className="pagina">
                <h1 className="tituloProductos">Sahumerios</h1>

                {/* Botones para cambiar los grupos */}
                <div className="botonesCategorias-productos">
                    <div className='botones-productos'>
                        {gruposDeImagenes.map((grupo, index) => (
                            <button
                                key={index}
                                onClick={() => cambiarGrupo(index)}
                                className={index === grupoIndex ? "botonActivo" : ""}
                            >
                                {nombresDeGrupos[index]}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Imágenes del grupo actual */}
                <div className="imagenesGrupo">
                    {gruposDeImagenes[grupoIndex].imagenes.map((imagen, index) => (
                        <div key={index} className="imagen-container" onClick={() => openModal(imagen, gruposDeImagenes[grupoIndex].nombres[index])}>
                            <img src={imagen} className="imghome" alt={`Imagen ${index + 1}`} />
                            {/*<p className="imagen-name">{gruposDeImagenes[grupoIndex].nombres[index]}</p>*/}
                        </div>
                    ))}
                </div>

                {modalOpen && <ModalImagenes imageInfo={selectedImage} onClose={closeModal} />}
            </div>
            <div className='antes-footer'></div>
        </>
    );
};

