import React from 'react';
import { FaInstagramSquare } from "react-icons/fa";

export const BotonInstagram = ({ iconSize, iconColor }) => {

    const iconContainerStyle = {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: '1000',
    };

    const iconStyle = {
        fontSize: iconSize,
        color: iconColor || '#D52577',
    };

    const tooltipStyle = {
        position: 'absolute',
        left: '-210px',
        top: '-43px',
        backgroundColor: '#fff',
        padding: '5px',
        borderRadius: '5px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        display: 'none',
        border: '1px solid #000',
    };

    const handleHover = () => {
        document.getElementById('nstagram-tooltip').style.display = 'block';
    };

    const handleLeave = () => {
        document.getElementById('nstagram-tooltip').style.display = 'none';
    };

    return (
        <div
            style={iconContainerStyle}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
        >
            <a className='botonIncio' href="https://www.instagram.com/ZULUZ1984/" target="_blank" rel="noopener noreferrer" style={iconContainerStyle}>
                <FaInstagramSquare style={iconStyle} />
            </a>
            <div id="nstagram-tooltip" style={tooltipStyle}>
                Seguime por Instagram
            </div>
        </div>
    );
};

