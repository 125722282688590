import React from 'react';
import '../../styles/modalImagenes.css'

export const ModalImagenes = ({ imageInfo, onClose }) => {
    const { url, name } = imageInfo;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content">
                <img src={url} alt={name} />
                <p className="image-name-modal">{name}</p>
                <button onClick={onClose}>Cerrar</button>
            </div>
        </div>
    );
};


