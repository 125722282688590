import React from 'react';
import { Link } from 'react-router-dom';

export const BotonCategoria = ({ to, imagenSrc, alt, texto }) => {
    return (
        <Link className="boton" to={to}>
            <img src={imagenSrc} alt={alt} />
            {texto}
        </Link>
    );
};


