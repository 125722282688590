import React, { useState } from 'react';
import { ModalImagenes } from './ModalImagenes';
import '../../styles/productos.css';

export const Portasahumerios = () => {
    const [grupoIndex, setGrupoIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ url: '', name: '' });

    const cambiarGrupo = (index) => {
        setGrupoIndex(index);
    };

    const openModal = (imageUrl, imageName) => {
        setSelectedImage({ url: imageUrl, name: imageName });
        setModalOpen(true);
    };


    const closeModal = () => {
        setSelectedImage({ url: '', name: '' });
        setModalOpen(false);
    };

    const gruposDeImagenes = [
        {
            nombres: [
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
                "Portasahumerio de vidrio 5x5cm",
          
                "Portasahumerio de vidrio",
                "Portasahumerio de vidrio",
                "Portasahumerio de vidrio",
                "Portasahumerio de vidrio",
                
                "Portasahumerio de vidrio",
                "Portasahumerio de vidrio",
                "Portasahumerio de vidrio",
                "Portasahumerio de vidrio",
                "Portasahumerio de vidrio",
                "Portasahumerio de vidrio",

                "Portasahumerio de vidrio",
                "Portasahumerio de vidrio",
                "Portasahumerio de vidrio",
                "Portasahumerio de vidrio",
                "Portasahumerio de vidrio",
                "Portasahumerio de vidrio",
            ],

            imagenes: [
                "/images/Portasahumerios/13.jpg",
                "/images/Portasahumerios/2.jpg", 
                "/images/Portasahumerios/3.jpg", 
                "/images/Portasahumerios/4.jpg", 
                "/images/Portasahumerios/5.jpg", 
                "/images/Portasahumerios/6.jpg", 
                "/images/Portasahumerios/7.jpg", 
                "/images/Portasahumerios/8.jpg", 
                "/images/Portasahumerios/9.jpg", 
                "/images/Portasahumerios/10.jpg", 
                "/images/Portasahumerios/11.jpg", 
                "/images/Portasahumerios/12.jpg", 
                "/images/Portasahumerios/14.jpg", 
                "/images/Portasahumerios/15.webp", 
                "/images/Portasahumerios/16.webp", 
                "/images/Portasahumerios/18.webp", 
                "/images/Portasahumerios/19.jpg", 
                "/images/Portasahumerios/20.jpg", 
                "/images/Portasahumerios/21.jpg", 
                "/images/Portasahumerios/22.jpg", 

                "/images/Portasahumerios/corto1.jpg", 
                "/images/Portasahumerios/corto2.jpg", 
                "/images/Portasahumerios/corto3.jpg", 
                "/images/Portasahumerios/corto4.jpg", 

                "/images/Portasahumerios/1-corto.jpg", 
                "/images/Portasahumerios/2-corto.jpg", 
                "/images/Portasahumerios/3-corto.jpg", 
                "/images/Portasahumerios/4-corto.jpg", 
                "/images/Portasahumerios/5-corto.webp", 
                "/images/Portasahumerios/6-corto.webp", 

                "/images/Portasahumerios/1-largo.jpg", 
                "/images/Portasahumerios/2-largo.jpg", 
                "/images/Portasahumerios/3-largo.jpg", 
                "/images/Portasahumerios/4-largo.jpg",
                "/images/Portasahumerios/5-largo.jpg", 
                "/images/Portasahumerios/6-largo.jpg",
            ],
        },
        {
            nombres: [
                "Portasahumerio de madera Dragón",
                "Portasahumerio de madera Dragón",
                "Portasahumerio de madera Dragón",
                "Portasahumerio de madera",
                "Portasahumerio de madera",
                "Portasahumerio de madera",
            ],


            imagenes: [
                "/images/Portasahumerios/1-dragon.jpg",
                "/images/Portasahumerios/2-dragon.jpg",
                "/images/Portasahumerios/3-dragon.jpg",
                "/images/Portasahumerios/m.jpg",
                "/images/Portasahumerios/m1.jpg",
                "/images/Portasahumerios/m3.jpg",
            ],
        },
        {
            nombres: [
                "Portasahumerio de yeso",
                "Portasahumerio de yeso",
                "Portasahumerio de yeso",
            ],


            imagenes: [
                "/images/Portasahumerios/yeso.jpg", 
                "/images/Portasahumerios/yeso1.jpg", 
                "/images/Portasahumerios/yeso2.jpg", 
            ],
        },
    ];

    const nombresDeGrupos = [
        "Portasahumerios de vidrio",
        "Portasahumerios de madera",
        "Portasahumerios de yeso",
    ];

    return (
        <>
            <div className="pagina">
                <h1 className="tituloProductos">Portasahumerios</h1>

                {/* Botones para cambiar los grupos */}
                <div className="botonesCategorias-productos">
                    <div className='botones-productos'>
                        {gruposDeImagenes.map((grupo, index) => (
                            <button
                                key={index}
                                onClick={() => cambiarGrupo(index)}
                                className={index === grupoIndex ? "botonActivo" : ""}
                            >
                                {nombresDeGrupos[index]}
                            </button>
                        ))}
                    </div>
                </div>

                {/* Imágenes del grupo actual */}
                <div className="imagenesGrupo">
                    {gruposDeImagenes[grupoIndex].imagenes.map((imagen, index) => (
                        <div key={index} className="imagen-container" onClick={() => openModal(imagen, gruposDeImagenes[grupoIndex].nombres[index])}>
                            <img src={imagen} className="imghome" alt={`Imagen ${index + 1}`} />
                            {/*<p className="imagen-name">{gruposDeImagenes[grupoIndex].nombres[index]}</p>*/}
                        </div>
                    ))}
                </div>

                {modalOpen && <ModalImagenes imageInfo={selectedImage} onClose={closeModal} />}
            </div>
            <div className='antes-footer'></div>
        </>
    );
};

